.title{
    text-align: center;
}

.ps{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    min-width: 25rem;
}

.disableSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.outerCard {
    padding-bottom: 2rem;
}

.card {
    box-shadow: .25rem .25rem .5rem .25rem rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: .25rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 30rem;
    max-width: 50rem;
    width: 50%;
    min-height: 10rem;
    color:black;
    text-decoration: none;
    background-color:white;
}

.card:hover {
    box-shadow: .25rem .5rem 1rem .25rem rgba(0, 0, 0, 0.2);
}

.container {
    padding-left: 1rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.center {
    align-items: center;
}

.overlayOff {
    display: none;
}

.overlayOn {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 500ms;
    opacity: 1;
}

.overlayOn .outerCard {
    position:relative;
    margin: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.overlayOn  .card {
    box-shadow: .25rem .25rem .5rem .25rem rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: .25rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 30rem;
    max-width: 80rem;
    width: 80%;
    min-height: 25rem;
    color:black;
    text-decoration: none;
    background-color:white;
}

.overlayOn .card .container  {
    padding-left: 1rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
}
