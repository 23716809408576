.myname {
    color:black;
    display: flex;
    text-decoration: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-self: center;
    font-weight: bold;
    font-size: large;
    cursor: default;
}
