/* Position and sizing of burger button */
.bm-burger-button {
    display: block;
    position: fixed;
    width: 1.25rem;
    height: 1rem;
    right: 1.5rem;
    top: 1.5rem;
    color: black;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: black;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 1rem;
    width: 1rem;
}

/* Color/shape of close button cross */
.bm-cross {
    background: black;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    overflow: auto;
}

/* General sidebar styles */
.bm-menu {
    background: white;
    padding: 2rem;
    font-size: large;
    overflow: visible !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: white;
}

/* Wrapper for item list */
.bm-item-list {
    color: white;
    padding: 1rem;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: white;
    margin-bottom: 1rem;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

/* Styling of overlay */
.bm-overlay {
    background: white;
}

.bm-item:hover {
    color: black;
}

/* Hides the Hamburger Menu when screen is bigger */
@media screen and (min-width: 720px) {
    .bm-burger-button {
        display: none;
    }
}